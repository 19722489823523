/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
import breakpoints from "assets/theme/base/breakpoints";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import PinterestIcon from "@mui/icons-material/Pinterest";
import GitHubIcon from "@mui/icons-material/GitHub";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import YouTube from "@mui/icons-material/YouTube";

function CenteredFooter({ company, links, socials, light }) {
  const { href, name } = company;

  const year = new Date().getFullYear();

  const renderLinks = links.map((link) => (
    <MKTypography
      key={link.name}
      component={Link}
      href={link.href}
      variant="body2"
      color={light ? "white" : "secondary"}
      fontWeight="regular"
    >
      {link.name}
    </MKTypography>
  ));

  const renderSocials = socials.map((social) => (
    <MKTypography
      key={social.link}
      component={Link}
      href={social.link}
      variant="body2"
      color={light ? "white" : "black"}
      fontWeight="regular"
    >
      {social.icon}
    </MKTypography>
  ));
  const [mobileView, setMobileView] = useState(false);
  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
        console.log(window.innerWidth)
        console.log(breakpoints.values.lg)
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
      } else {
        setMobileView(false);
      }
      console.log(mobileView)
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);
  
  return (
    <MKBox component="footer">
      <Grid container justifyContent="center">
        {/* <Grid item xs={10} lg={8}>
          <Stack
            direction="row"
            flexWrap="wrap"
            justifyContent="center"
            spacing={{ xs: 2, lg: 3, xl: 6 }}
            mb={3}
          >
            {renderLinks}
          </Stack>
        </Grid> */}
         
          {mobileView == true ? <Grid></Grid> :( <Grid
            item
            xs={12}
            lg={6}
            textAlign={{ xs: "center"}}
            mr="auto"
            mb={{ xs: 3, lg: 0 }}
            py={16} pr={0} pl={{ xs: 6, sm: 12 }} my="auto"
          >
             <MKTypography variant="h3" color="black" mb={1}>
                Contact Information
              </MKTypography>
              <MKTypography variant="body2" color="black" opacity={0.8} mb={3}>
                Our Team will get back to you within 24
                hours.
              </MKTypography>
     
          </Grid>)}
          <Grid item xs={12} lg={6} ml="auto" textAlign={{ xs: "center"}}><MKBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
          >
            <MKBox py={mobileView == true ? 2 : 10 } pr={10} pl={{ xs: 6, sm: 12 }} my="auto">
             
              <MKBox display="flex" p={1}>
                <MKTypography variant="button" color="black">
                  <i className="fas fa-phone" />
                </MKTypography>
                <MKTypography
                  component="span"
                  variant="button"
                  color="black"
                  opacity={0.8}
                  ml={2}
                  fontWeight="regular"
                >
                  +90 530 300 20 10
                </MKTypography>
              </MKBox>
              <MKBox display="flex" p={1}>
                <MKTypography variant="button" color="black">
                  <i className="fas fa-phone" />
                </MKTypography>
                <MKTypography
                  component="span"
                  variant="button"
                  color="black"
                  opacity={0.8}
                  ml={2}
                  fontWeight="regular"
                >
                  +90 545 764 57 34 
                </MKTypography>
              </MKBox>
              <MKBox display="flex" p={1}>
                <MKTypography variant="button" color="black">
                  <i className="fas fa-phone" />
                </MKTypography>
                <MKTypography
                  component="span"
                  variant="button"
                  color="black"
                  opacity={0.8}
                  ml={2}
                  fontWeight="regular"
                >
                  +90 544 717 53 17
                </MKTypography>
              </MKBox>
              <MKBox display="flex" color="black" p={1}>
                <MKTypography variant="button" color="black">
                  <i className="fas fa-envelope" />
                </MKTypography>
                <MKTypography
                  component="span"
                  variant="button"
                  color="black"
                  opacity={0.8}
                  ml={3}            
                  fontWeight="regular"
                  marginLeft="17px"
                >
                  info@artformthermoform.com
                </MKTypography>
              </MKBox>
              <MKBox display="flex" color="black" p={1}>
                <MKTypography variant="button" color="black">
                  <i className="fas fa-map-marker-alt" />
                </MKTypography>
                <MKTypography
                  component="span"
                  variant="button"
                  color="black"
                  opacity={0.8}
                  margin-left="20px"
                  ml={mobileView == true ? 1.6 : 1.3}
                  fontWeight="regular"
                >Orhangazi Mahallesi Mimsan Sanayi Sitesi 1717. Sokak No:5 BAHÇEŞEHİR/ESENYURT/İSTANBUL
                </MKTypography>
              </MKBox>
            </MKBox>
          </MKBox></Grid>
      
        {<Grid item xs={12} lg={12}>
          <Stack
            display="flex"
            direction="row"
            justifyContent="center"
            spacing={3}
            mt={1}
            mb={3}
          >
            {renderSocials}
          </Stack>
        </Grid>}
        <Grid item xs={12} lg={12} sx={{ textAlign: "center",marginBottom:"10px" }}>
          <MKTypography variant="body2" color={light ? "black" : "black"}>
            Copyright &copy; {year} Material by{" "}
            <MKTypography
              component={Link}
              href={href}
              target="_blank"
              rel="noreferrer"
              variant="body2"
              color={light ? "white" : "black"}
            >
              {name}
            </MKTypography>
            .
          </MKTypography>
        </Grid>
      </Grid>
    </MKBox>
  );
}

// Setting default values for the props of CenteredFooter
CenteredFooter.defaultProps = {
  company: { href: "https://www.creative-tim.com/", name: "AÖ" },
  links: [
    { href: "https://www.creative-tim.com/", name: "Company" },
    { href: "https://www.creative-tim.com/presentation", name: "About Us" },
    { href: "https://www.creative-tim.com/presentation", name: "Team" },
    { href: "https://www.creative-tim.com/templates/react", name: "Products" },
    { href: "https://www.creative-tim.com/blog", name: "Blog" },
    { href: "https://www.creative-tim.com/license", name: "License" },
  ],
  socials: [
     { icon: <FacebookIcon fontSize="large"/>, 
       link: "https://www.facebook.com/profile.php?id=100090895774717&mibextid=9R9pXO" },
     {
       icon: <YouTube fontSize="large"/>,
      link: "https://youtube.com/@artformthermoform?si=pnfYiA2y-ZmegiAV",
     },
    {
      icon: <InstagramIcon fontSize="large"/>,
      link: "https://instagram.com/artformthermoform?igshid=NzZhOTFlYzFmZQ==",
    },
  ],
  light: false,
};

// Typechecking props for the CenteredFooter
CenteredFooter.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
  socials: PropTypes.arrayOf(PropTypes.object),
  light: PropTypes.bool,
};

export default CenteredFooter;
